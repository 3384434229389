@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

body {
    font-family: "IBM Plex Sans",sans-serif;

    p {
        font-size: 1.13rem;
        line-height: 1.8rem;

        a {
            color: #009E76;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: "IBM Plex Sans", sans-serif;
}

.card {
    box-shadow: -5px 15px 25px rgba(15, 23, 42, 0.1);

    @media(max-width: 767px){
        max-width: 90vw;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    canvas {
        max-height: 90%;
        object-fit: contain;
        max-width: 90%
    }

    p, li {
        color: theme('colors.slate.200');
        font-size: 18px;
        line-height: 28px;
    }
}

.filters {
    position: sticky;
    top: 30px;
    z-index: 10;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);

    .ais-SearchBox-submit, .ais-SearchBox-reset {
        display: none
    }

    input[type=search],
    select{
        -webkit-appearance: none;
        width: 100%;
        padding: 12px;
        color: rgba(0, 0, 0, 0.76);
        border: 1px rgba(0, 0, 0, 0.24) solid;
        border-radius: 8px;
    }

    .select {
        position: relative;

        select {
            padding-right: 32px;
            &:active, &:focus {
                outline: 0;
            }
        }

        svg {
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translateY(-50%);
        }
    }

    .search {
        position: relative;

        input {
            padding-left: 34px;

            &:active, &:focus {
                outline: 0;
            }
        }

        svg {
            position: absolute;
            top: 50%;
            left: 12px;
            transform: translateY(-50%);
        }
    }
}

.card-full {
    overflow: hidden;
    max-height: 0;
    transition: .5s max-height;
    z-index: 9999;
    //position: fixed;
    top: calc((100vh - 720px) / 2);
    left: 50%;
    transform: translateX(-50%);
    width: theme('container.2xl');

    @media(max-width: 1535px){
        width: theme('container.xl');
    }

    @media(max-width: 1280px){
        width: theme('container.lg');
    }

    @media(max-width: 992px){
        width: theme('container.md');
    }

    @media(max-width: 767px){
        width: 90%;
    }

    > div {
        opacity: 0;
        transition: .5s opacity;

        @media(max-width: 1023px){
            overflow: scroll;

            &.grid {
                > div {
                    height: 360px;

                    + div {
                        min-height: 360px;
                        height: fit-content;
                        padding-bottom: 110px;
                    }
                }
            }
        }
    }

    &.active {
        max-height: 720px;

        > div {
            opacity: 1;
            transition-delay: .5s;
        }
    }

    img {
        max-height: 630px;
    }

    ul {
        margin-top: 10px;
        padding-left: 16px;
        list-style: disc;
    }
}

.hero {
    background: #00281D;
    background-size: cover;
    position: relative;
    overflow: hidden;

    .circle {
        position: absolute;

        &.circle-1 {
            z-index: 3;
            width: 33%;
            animation: 30s linear infinite spin;
            bottom: -40%;
            right: -14%;
        }

        &.circle-2 {
            width: 18%;
            z-index: 2;
            animation: 40s linear infinite reverse spin;
            bottom: -42%;
            right: 5%;
        }

        &.circle-3 {
            width: 12%;
            z-index: 2;
            animation: 30s linear infinite reverse spin;
            top: 19%;
            left: 14%;
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ais-Hits-item {
    position: relative;
    height: 720px;

    &:nth-of-type(odd) .card-full{
        left: 0;
    }

    &:nth-of-type(even) .card-full{
        left:-706px;
    }

    //&:nth-of-type(3n+3) .card-full{
    //    left:-931px;
    //}
}

.ais-RefinementList-label {
    color: #fff;

    input {
        margin-right: 5px;
    }
}

.select-filter {
    padding: 5px;
    min-width: 50%;
}

.ais-RefinementList-count {
    display: inline-block;
    margin-left: 5px;
    opacity: .5;

    &:before {
        content: '(';
    }

    &:after {
        content: ')';
    }
}

.open-card {
    .canvas-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .canvas-container {
        height: 100% !important;
    }

    canvas {
        height: auto;
        max-height: 90%;
        object-fit: contain;
    }
}

footer {
    background: linear-gradient(180deg, #F8F8F9 0%, rgba(234, 236, 237, 0) 100%), #FFFFFF;

    li {
        font-size: 1.125rem;

        a {
            font-weight: 500;
        }
    }

    hr:after {
        border-bottom: 1px dashed #d5d9dc;
        width: 100%
    }

    hr:after,hr:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    hr:before {
        display: block;
        background: -webkit-gradient(linear,left top,right top,from(#00c693),to(#af45f4));
        background: linear-gradient(90deg,#00c693,#af45f4);
        width: 30%;
        height: 4px;
        border-radius: 2px;
        z-index: 2
    }

    hr {
        margin-top: 3em;
        margin-bottom: 3em;
        width: 100%;
        border: 0;
        height: 1px;
        position: relative;
        background: none;
    }
}

.home-hero {
    background: #00281D linear-gradient(360deg, rgba(0, 119, 88, 0.3) 0%, rgba(0, 158, 118, 0) 50.5%);

    .inner {
        padding: 120px 0 80px;
        background: url('/images/home-hero.svg');
        background-repeat: no-repeat;
        background-position: top 20% center;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    h2 {
        font-size: 30px;
        line-height: 36px;
        color: #fff;
    }

    h1 {
        padding: 16px 40px;
        background: #004F3B;
        font-weight: 500;
        font-size: 96px;
        line-height: 110%;
        letter-spacing: -3px;
        color: #FFFFFF;
        margin: 24px 0 0 0;
    }

    h3 {
        padding: 16px 40px;
        background: #FFB700;
        font-weight: 500;
        font-size: 48px;
        line-height: 110%;
        color: #00281D;
    }

    svg {
        margin-top: 24px;
    }
}

.home-section {
    padding: 80px 0;

    @media(max-width: 992px){
        padding: 40px 0;
    }

    p {
        font-weight: 300;
        margin-bottom: 1.2rem;
    }

    .container {
        max-width: 1392px;
    }

    &.bg-green-900 + .bg-green-900 {
        padding-top: 20px;
    }
}

.sticky-nav {
    .container {
        max-width: 1392px;
    }

    @media(max-width: 1100px){
        a {
            font-size: 14px;
        }
    }
}

.graph-wrap {
    @media (min-width: 993px) {
        background: rgba(0,0,0,.02);
        border-radius: 24px;
        padding: 24px;
        border: 1px solid rgba(0,0,0,.04);
    }
}

footer {
    border-top: 1px solid;
    border-image: linear-gradient(to right, #00C693, #AF45F4) 1;
    background: linear-gradient(180deg, #F8F8F9 0%, rgba(234, 236, 237, 0) 100%), #FFFFFF;

    hr {
        width: 100%;
        height: 1px;
        background: linear-gradient(to right, #00C693, #AF45F4);
    }
}


.btn-outline {
    border: 1px solid #fff;
    color: #fff;
    background: none;
    -webkit-appearance: none;

    border-radius: 9999px;

    &:hover {
        background: rgba(255,255,255,.24)
    }
}

.mobile-nav-button {
    color: #fff;
    padding: 8px 0;
    font-size: 18px;
    background: rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(4px);
    border-radius: 6px;

    > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 6px;

        &.open {
            display: none;
        }
    }

    &.open {
        .closed {
            display: none;
        }

        .open {
            display: flex;
        }

        + .mobile-nav {
            display: flex;
        }
    }
}

.mobile-nav {
    display: none;
    width: 100%;
    flex-direction: column;
    gap: 18px;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    padding: 18px;

    a {
        font-size: 18px;
    }
}

.f2card-v2 {
    display: flex;
    background-color: #fff;
    border: 1px rgba(0, 0, 0, 0.04) solid;
    transition: .2s all;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    p {
        margin: 0;
        font-size: 16px;
        line-height: 1.6;
    }

    ul {
        margin: 1rem 0 0 0;

        li {
            padding-left: 1.3rem;
            margin-top: 0;
            line-height: 1;

            a {
                @apply text-green-600 font-normal text-base;
            }

            &:before {
                background-color: #00C693;
            }
        }
    }

    &:hover {
        box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    }

    &.with-hover {
        cursor: pointer;
    }

    h4 {
        line-height: 1.3;
    }

    .badge {
        @apply text-xs font-medium rounded-xs py-1 px-2 inline-block mt-4;
        color: #00668C;
        border: 1px solid #3399BF;
        background: #CCE6EF;
    }

    img {
        margin: 0 !important;
    }

    &.full-height {
        height: 100%;
    }

    &.stacked {
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;

        img {
            border-radius: 12px 12px 0 0;
        }
    }

    .content {
        padding: 24px;
        height: 100%;

        ul, li, ul a {
            font-size: 16px;
        }
    }

    &.fixed-footer {
        .content {
            height: 100%;
            position: relative;
            padding-bottom: 66px;

            .footer {
                position: absolute;
                bottom: 24px;
                left: 50%;
                transform: translateX(-50%);
                padding: 0 24px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        &.extra-bottom-p {
            .content {
                padding-bottom: 85px;
            }
        }
    }

    &.small {
        column-gap: 8px;
        box-shadow: none;
        border: 0;
        padding: 0;
        margin: 0;

        p {
            margin: 0;
            font-size: 14px;
            line-height: 1;
        }
    }

    &.small p + p {
        margin-top: 4px;
    }

    &.small:hover {
        box-shadow: none;

        p {
            color: #007758;
        }
    }

    &.in-sidebar {
        background: rgba(0, 0, 0, 0.02);
        padding: 12px;
        flex-direction: column;
        box-shadow: none;
        border-radius: 4px;

        h6 {
            @apply text-base font-normal;
            line-height: 1.2;
            color: rgba(0, 0, 0, 0.92);
            margin: 0;
        }

        p {
            font-size: 14px;
            line-height: 18px;
            margin: 4px 0 0 0;
            font-weight: 300;
        }

        &:hover {
            background: #F9FAFA;
            box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
            border: 1px #007758 solid;
        }
    }

    &.is-horizontal {
        overflow: hidden;

        @media(max-width: 767px){
            flex-wrap: wrap;

            .thumb {
                width: 100%;
            }
        }

        @media(min-width: 768px){
            .thumb {
                flex: 0 0 336px;

                img {
                    object-fit: cover;
                    width: 336px;
                    object-position: left top;
                    height: 100%;
                }
            }
        }

        .content {
            flex: 1 0 0%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .footer {
            display: flex;
            justify-content: space-between;
        }
    }
}